import React, { useState, useRef } from 'react';
import AppBar from '@material-ui/core/AppBar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Pets';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import Loggati from './Loggati';
import { LOLAPROXY } from './utility/const';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BarraNavigazione = () => {

  let history = useHistory();
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const anchorRef = useRef(null);
  const handleMenuClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  const classes = useStyles();

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton ref={anchorRef} edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
          onClick={handleToggle}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
            Lola Corre
        </Typography>
        <Button color="inherit"
          onClick={handleClickOpen}>            
          Login
        </Button>
        <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Login
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Loggati />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Chiudi
          </Button>
        </DialogActions>
      </BootstrapDialog>

        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleMenuClose}>
                  <MenuList autoFocusItem={open} id="menu">
                  <MenuItem>
                      <Link href="/mng/contatti" underline="none" >
                        Contatti
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link href="/mng/lezioni" underline="none" >
                        Lezioni
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link href="/mng/calendar" underline="none" >
                        Tabella Lezioni
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link href="/mng/add" underline="none" >
                        Nuovo Contatto
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link href={`${LOLAPROXY}/api/mng/`} underline="none" >
                        x Apple
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link href="/mng/about" underline="none" >
                        About
                      </Link>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
          
      </Toolbar>
    </AppBar>
  )
}

export default BarraNavigazione;