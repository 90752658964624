import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import { LOLAPROXY } from './utility/const';
import DataGrid, {
    Column,
    SearchPanel,
    GroupPanel,
    FilterRow,
    FilterPanel,
    HeaderFilter,
  } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import 'devextreme/dist/css/dx.material.orange.dark.compact.css';
import LinearProgress from '@material-ui/core/LinearProgress';

  const linkWAPP = (data) => {
    return <a href={data.value}> <img src="wapp.png" alt="WhatsApp" width="20" height="20"></img> </a>
 };
 const linkScheda = (data) => {
    return <a href={data.value}> <img src="scheda.png" alt="Scheda Cliente" width="20" height="20"></img> </a>
  };
  const linkEdit = (data) => {
    return <a href={data.value}> <img src="edit.png" alt="Edita Cliente" width="20" height="20"></img> </a>
  };
  const linkDoc = (data) => {
    return <a href={data.value}> <img src="doc.png" alt="Doc Cliente" width="20" height="20"></img> </a>
  };
 

const ContattiList = () => {
    const [contatti, setContatti] = useState([]);
    const [initialFetchOnly] = useState(true);
    const [openLinearProgress, setOpenLinearProgress] = React.useState(true);

    useEffect(() => {
      let token = localStorage.getItem('token');
      axios.get(LOLAPROXY + '/api/mng/contatti',
      { 'headers':
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        } 
      }).then(response => {setContatti(response.data); setOpenLinearProgress(false);});
      console.log(contatti);
      
    }, [initialFetchOnly])

    return (
      
        <div id="data-grid-ListaContatti">
          {openLinearProgress && <LinearProgress />}
          <Typography variant="h6">
              Lista soci
          </Typography>
          <DataGrid
            dataSource={contatti}
            keyExpr="_id"
            showBorders={true}
            SearchPanel visible={true}    
            >  
            <SearchPanel visible={true} />
            <GroupPanel visible={true} />
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <HeaderFilter visible={true} />

            <Column dataField="nome" dataType="string" />
            <Column dataField="cell" dataType="string" hidingPriority={1}/>
            <Column dataField="resoconto" dataType="string" hidingPriority={2}/>
            <Column dataField="tessera" dataType="string" hidingPriority={2}/>
            <Column dataField="linkscheda" dataType="string" caption ="Scheda" dataType="string" width="30" cellRender={linkScheda} hidingPriority={4}/>
            <Column dataField="linkedit" dataType="string" caption ="Modifica" dataType="string" width="30" cellRender={linkEdit} hidingPriority={4}/>
            <Column dataField="linkdoc" dataType="string" caption ="Doc Google" dataType="string" width="30" cellRender={linkDoc} hidingPriority={4}/>
            <Column dataField="linkwapp" dataType="string" caption ="WhatsApp" dataType="string" width="30" cellRender={linkWAPP} hidingPriority={5}/> 
          </DataGrid>
        </div>
    );
}

export default ContattiList;