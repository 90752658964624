import React, {useState, useEffect}from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import { LOLAPROXY } from './utility/const';
import { addModRicevuta, controlloPagamenti } from './utility/dateUtils';
import { contattoSchema, istruttore, pagLezione } from './data.js';
import { v4 as uuid } from 'uuid';
import { Ricevuta } from './Ricevuta';
import { PDFDownloadLink } from '@react-pdf/renderer';

var contatto = contattoSchema;
let indexPag = -1;
let indexAbb = -1;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

export default function ModPagamento() {
  const classes = useStyles();
  const [item, setItem] = useState({});
  let location = useLocation();
   let history = useHistory();
  
    useEffect(() => {
      let token = localStorage.getItem('token');
      console.log('location.pathname --> ' + location.pathname);
      axios.get(LOLAPROXY + '/api' + location.pathname,
      { 'headers':
          {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          } 
        }).then(response => setItem(response.data[0])); // esempio pathname "/modpagamento/689a634c-ca48-4461-bc08-272a0c896423"
    }, [location]) //useEffect run only if location change
   
    useEffect(() => {
        console.log('preparazione contatto da item...');
        let idPagamento = location.pathname.replace("/mng/modpagamento/","");
        contatto = item;

        if(contatto.abbonamenti) {
            setNome(contatto.nome);
            setCognome(contatto.cognome);
            setNomeCane(contatto.nome_cane);
            
            for(let a = 0; a < contatto.abbonamenti.length; a++)
            {
                indexPag = contatto.abbonamenti[a].pagamenti.findIndex(function(item, i){
                    return item.ID === idPagamento
                });
                console.log("indexAbb: "+ a + "indexPag: " + indexPag);
                if(indexPag > -1) {
                    indexAbb = a;
                    console.log("indexAbb: "+ a + "indexPag: " + indexPag)
                    break;
                }
            }

            let dataDaConvertire = new Date(contatto.abbonamenti[indexAbb].pagamenti[indexPag].data);
            setData(new Date(dataDaConvertire.getTime() - (dataDaConvertire.getTimezoneOffset() * 60000)).toISOString().slice(0,-8));
            setCifra(contatto.abbonamenti[indexAbb].pagamenti[indexPag].cifra);
            
            if(contatto.abbonamenti[indexAbb].pagamenti[indexPag].ricevuta)
            {
              titoloRic = contatto.abbonamenti[indexAbb].pagamenti[indexPag].ricevuta[0].titolo;
              let dataRicDaDB = contatto.abbonamenti[indexAbb].pagamenti[indexPag].ricevuta[0].data && new Date(contatto.abbonamenti[indexAbb].pagamenti[indexPag].ricevuta[0].data).toLocaleDateString();
              dataRic = dataRicDaDB;
              nomeRic = contatto.abbonamenti[indexAbb].pagamenti[indexPag].ricevuta[0].socio;
              descrizioneRic = contatto.abbonamenti[indexAbb].pagamenti[indexPag].ricevuta[0].descrizione;
              totaleRic = contatto.abbonamenti[indexAbb].pagamenti[indexPag].ricevuta[0].totale;
              setTitoloPdf(titoloRic);
              const dataDaConvertirePDF = new Date(contatto.abbonamenti[indexAbb].pagamenti[indexPag].ricevuta[0].data);
              setDataNomeFile(new Date(dataDaConvertirePDF.getTime() - (dataDaConvertirePDF.getTimezoneOffset() * 60000)).toISOString().slice(0,-8));
              setDataPdf(dataRic);
              setNomePdf(nomeRic);
              setDescrizionePdf(descrizioneRic);
              setTotalePdf(totaleRic);
    
              setRicevutaCreata(true);
            }            
        }

        console.log('FINE prepara contatto da item');
    }, [item]) //useEffect run only if item change

  const [nome, setNome] = useState('');
  const [cognome, setCognome] = useState('');
  const [nome_cane, setNomeCane] = useState('');
  //costanti di stato per gestione form e creazione pdf Ricevuta
  const [data, setData] = useState('');
  const [cifra, setCifra] = useState('');
  const [nomePdf, setNomePdf] = useState('');
  const [dataPdf, setDataPdf] = useState('');
  const [descrizionePdf, setDescrizionePdf] = useState('');
  const [totalePdf, setTotalePdf] = useState('');
  const [titoloPdf, setTitoloPdf] = useState('');
  const [dataNomeFile, setDataNomeFile] = useState('');

  const [ricevutaCreata, setRicevutaCreata] = useState (false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openRicevuta, setOpenRicevuta] = React.useState(false);
  let nomeRic = '';
  let dataRic = '';
  let descrizioneRic = '';
  let totaleRic = 0;
  let titoloRic = '';
  
  //------------------------
  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleClickOpenDialogRicevuta = () => {
    setOpenRicevuta(true);
  };

  const handleCloseDialogRicevuta = () => {
    setOpenRicevuta(false);
  };
  const handleSubmit = () => {
    setLoading(true);
    setIsError(false);
    
    contatto.nome = nome;
    contatto.cognome = cognome;
    contatto.nome_cane = nome_cane;
    contatto.abbonamenti[indexAbb].pagamenti[indexPag].data = data;
    contatto.abbonamenti[indexAbb].pagamenti[indexPag].cifra = cifra;

    contatto = controlloPagamenti(contatto);
    console.log('/api/mng/modpagamento/',JSON.stringify(contatto, null, 2));
    let token = localStorage.getItem('token');
    axios.post(LOLAPROXY + '/api/mng/add/', contatto,
    { 'headers':
          {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          } 
        }).then(res => {
      setLoading(false);

    }).catch(err => {
      setLoading(false);
      setIsError(true);
    });
  }

  const handleDelete = () =>{
    setLoading(true);
    setIsError(false);
    
    contatto.abbonamenti[indexAbb].pagamenti.splice(indexPag,1);

    contatto = controlloPagamenti(contatto);
    console.log('/api/mng/modpagamento/',JSON.stringify(contatto, null, 2));
    let token = localStorage.getItem('token');
    axios.post(LOLAPROXY + '/api/mng/add/', contatto,
    { 'headers':
          {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          } 
        }).then(res => {
      setLoading(false);
      history.push("/mng/contatti/");

    }).catch(err => {
      setLoading(false);
      setIsError(true);
    });
  }
  const handleSubmitAdd = () => {
    // aggiungi nuovo pagamento vuoto nell'array con ID nuovo
    let nuovoPagamento = contattoSchema[0].abbonamenti[0].pagamenti[0];
    nuovoPagamento.ID = uuid();
    console.log(nuovoPagamento.ID);
    contatto.abbonamenti[indexAbb].pagamenti.push(nuovoPagamento);
    handleSubmit();
    setRicevutaCreata(false);
    history.push(`/mng/modpagamento/${nuovoPagamento.ID}`);
  }

  const handleAddModRicevuta = () => {
    //aggiungi o modifica ricevuta
    titoloRic = "Ricevuta per formazione";
    nomeRic = contatto.nome + " " + contatto.cognome;
    let descr = contatto.abbonamenti[indexAbb].tipo ? contatto.abbonamenti[indexAbb].tipo : "Pacchetto";
    descrizioneRic = descr;
    let cifraTot = cifra ? cifra + ",00€" : "0,00€";
    totaleRic = cifraTot;

    const nuovaData = new Date();
    contatto = addModRicevuta(contatto, indexAbb, -1, indexPag, nuovaData, titoloRic, nomeRic, descrizioneRic, totaleRic);
    setTitoloPdf(titoloRic);
    setDataPdf(nuovaData.toLocaleDateString());
    setNomePdf(nomeRic);
    setDescrizionePdf(descrizioneRic);
    setTotalePdf(totaleRic);
    setDataNomeFile(new Date(nuovaData.getTime() - (nuovaData.getTimezoneOffset() * 60000)).toISOString().slice(0,-8));
    setRicevutaCreata(true);
    setOpenRicevuta(false);
    handleSubmit();
  }


  return (
    <div>
        <Typography variant="h6">
            {nome}  {cognome}
        </Typography>
        <Typography variant="h6">
            Cane: {nome_cane}
        </Typography>
        <Typography variant="h7">
        Resoconto: {contatto.resoconto}
      </Typography>
        <form className={classes.root} noValidate autoComplete="off">
        <TextField id="data" type = "datetime-local" label="Data" value={data} onChange={e => setData(e.target.value)}/>
        <TextField id="cifra" label="Cifra" value={cifra} onChange={e => setCifra(e.target.value)} />

        <Button type="submit" onClick={handleSubmit} disabled={loading} variant="contained">{loading ? 'Attendi...' : 'Salva'} </Button>
        <Button type="submit" onClick={handleSubmitAdd} disabled={loading} variant="contained">{loading ? 'Attendi...' : 'Nuovo Pagamento'} </Button>
        <Button variant="outlined" onClick={handleClickOpenDialog}>
          Elimina pagamento
        </Button>
        <Dialog
          open={open}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"ATTENZIONE!!!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Eliminare definitivamente il pagamento?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDelete}>SI</Button>
            <Button onClick={handleCloseDialog} autoFocus>NO </Button>
          </DialogActions>
        </Dialog>
        { (cifra > 0) &&
          <Button variant="outlined" onClick={handleClickOpenDialogRicevuta}>
            Ricevuta
          </Button>
        }
        <Dialog
          open={openRicevuta}
          onClose={handleCloseDialogRicevuta}
          aria-labelledby="ricevuta-dialog-title"
          aria-describedby="ricevuta-dialog-description"
        >
          <DialogTitle id="ricevuta-dialog-title">
            {"RICEVUTA"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="ricevuta-dialog-description">
              Creare o aggiornare ricevuta?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddModRicevuta}>OK</Button>
            <Button onClick={handleCloseDialogRicevuta} autoFocus>Annulla</Button>
          </DialogActions>
        </Dialog>
        </form>
        <div>
          { ricevutaCreata &&
            <PDFDownloadLink document={<Ricevuta socio={nomePdf}
                                      data={dataPdf}
                                      descrizione={descrizionePdf}
                                      totale={totalePdf}
                                      titolo={titoloPdf}
                                      />}
                              fileName={"RicevutaLolaCorre"+dataNomeFile+".pdf"}>
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : 'Scarica ricevuta!'
              }
            </PDFDownloadLink>
          }
        </div>
        
    </div>
  );
}