export const EMPTY_STR = '';
export const SLASH = '/';
export const SCADE_IL = 'Scade il: ';
export const COLON = ':';
export const SPACE = ' ';
export const DASH = '-';
export const SDASHS = ' - ';
export const WEEKDAY = ['domenica','lunedì','martedì','mercoledì','giovedì','venerdì','sabato'];
export const MONTHNAME = ['gennaio','febbraio','marzo','aprile','maggio','giugno','luglio','agosto','settembre','ottobre','novembre','dicembre'];

export const L_DAPAGARE = 'da pagare';
export const L_PAGATA = 'pagata';
export const L_COMPRESA = 'compresa';
export const ERR_L_DAPAGARE = 'lezione da pagare ';

export const I_FABIO = 'Fabio';
export const I_ANNA = 'Annalisa';
export const I_CINZIA = 'Cinzia';
export const I_FRANCESCA = 'Francesca';
export const I_IRENE = 'Irene';

export const ABB_FUORIABB = 'Fuori Abb';
export const ABB_CORSOBASE = 'Corso Base';
export const ABB_MINICORSO = 'Mini Corso';
export const ABB_AREASGAMBO = 'Area Sgambo';
export const ABB_ABBSPORT = 'Abb Sport';
export const ABB_TESSERAMENTO = 'Tessera Socio';
export const ABB_PROVA = 'Abb Prova';
export const ABB_GFORMAZIONE = 'Giornate Formazione';
export const ABB_AFFCAMPO = 'Affitto Campo';
export const ABB_STAGE = 'STAGE';
export const ABB_ALTRO = 'Altro';

export const LOLAPROXY = 'https://lolacorre.ddns.net:5555';
//export const LOLAPROXY = 'http://localhost:5555';
//export const LOLAPROXY = 'https://192.168.8.11:5555';