import React, {useState, useEffect}from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';

import DataGrid, {
  Column,
  FormItem,
  Summary,
  TotalItem,
  GroupItem,
  SearchPanel,
  GroupPanel,
  FilterRow,
  FilterPanel,
  HeaderFilter,
  Paging,
  ColumnChooser,
} from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import 'devextreme/dist/css/dx.material.orange.dark.compact.css';
import { locale } from 'devextreme/localization';
import { LOLAPROXY } from './utility/const';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const notesEditorOptions = { height: 100 };
const linkWAPP = (data) => {
   return <a href={data.value}> <img src="wapp.png" alt="WhatsApp" width="20" height="20"></img> </a>
};
const linkModCli = (data) => {
  return <a href={data.value}> <img src="edit.png" alt="WhatsApp" width="20" height="20"></img> </a>
};

export default function Calendar() {
  const classes = useStyles();
  const [item, setItem] = useState({});
  const [initialFetchOnly] = useState(true);
  const oggiStr = new Date();
  const filterValue = ['giorno', '>=', oggiStr];
  locale('it');
  let DateFormatDataTime = {month: 'long', day: '2-digit', weekday: 'long', hour: 'numeric', minute: 'numeric'};
  let DateFormatData = {month: 'long', day: '2-digit', weekday: 'long'};
  let DateFormatTime = {hour: 'numeric', minute: 'numeric'};

    useEffect(() => {
        let token = localStorage.getItem('token');
        axios.get(LOLAPROXY + '/api/mng/calendar',
        { 'headers':
          {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          } 
        }).then(response => {
            const listaStringify = JSON.stringify(response.data, null, 2);
            var resMod = JSON.parse(listaStringify, function (key, value) {  //trasformo stringhe in oggetti dove serve
                switch(key){
                    case "giorno":
                        return new Date(value);
                    case "ora": // ora non esiste più ma tengo cmq la case
                        return new Date(value).toLocaleDateString(undefined,{ hour: 'numeric', minute: 'numeric'});
                    default:
                    return value;
                }      
            });

            setItem(resMod);
        });
        console.log('item--------------->',item);
    }, [initialFetchOnly])
    //<Column dataField="giorno" dataType="date" format={DateFormatDataTime} groupIndex={0}/>
    
    return (
    <div>
        <div id="data-grid-Lezioni">
        <Typography variant="h6">
            Calendario Lezioni
            </Typography>
        <DataGrid
            defaultFilterValue={filterValue}
            dataSource={item}
            keyExpr="ID"
            showBorders={true}
            SearchPanel visible={true}>
            <SearchPanel visible={true} />
            <GroupPanel visible={true} />
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <HeaderFilter visible={true} />
            <Paging defaultPageSize={30} />

            <Column dataField="giorno" dataType="date" format={DateFormatDataTime} />
            <Column dataField="cliente" dataType="string" width = "175"/>
            <Column dataField="istruttore" dataType="string" caption = "Istr." width = "70" groupIndex={0} hidingPriority={9}/>
            <Column dataField="tipo" dataType="string" hidingPriority={7}/>
            <Column dataField="luogo" width = "70" dataType="string" hidingPriority={4}/>
            <Column dataField="cell" dataType="string" hidingPriority={1}/>
            <Column dataField="cifra" width = "50" dataType="string" hidingPriority={5}/>
            <Column dataField="pagata" dataType="string" width = "70" hidingPriority={6}/>
            <Column dataField="xlola" width = "60" caption="x Lola" dataType="string" hidingPriority={2}/>
            <Column dataField="valorelez" width = "60" caption="Valore"  hidingPriority={3}/>
            <Column dataField="note" hidingPriority={0}>
            <FormItem colSpan={2} editorType="dxTextArea" editorOptions={notesEditorOptions} />
            </Column>      
            <Column dataField="linkModCli" caption ="Modifica" dataType="string" width="30" cellRender={linkModCli} hidingPriority={8}/>
            <Summary>
              <TotalItem
                column="xlola"
                summaryType="count" 
                displayFormat="N.Lez: {0}"/>
              <TotalItem
                column="valorelez"
                summaryType="sum"
                displayFormat="TOT presunto: {0}€"/>
              <GroupItem
                column="istruttore"
                summaryType="count"
                displayFormat="N.Lezioni: {0}"/>
              <GroupItem
                column="valorelez"
                summaryType="sum"
                displayFormat="Incasso presunto: {0}€" />
            </Summary>
           
        </DataGrid>
        </div>
    </div>
  );
}