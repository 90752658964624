import React, { useState, useRef, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Pets';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { LOLAPROXY } from './utility/const';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const BarraCliente = () => {

  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [linkEdit, setLinkEdit] = useState(false);
  const [linkWapp, setLinkWapp] = useState(false);
  const [linkDocGoogle, setLlinkDocGoogle] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const anchorRef = useRef(null);
  const handleMenuClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const classes = useStyles();

  const [item, setItem] = useState({});
    let location = useLocation();
    const [statoToken, setStatoToken] = useState(false);

    useEffect(() => {
        axios.get(LOLAPROXY + '/api' + location.pathname).then(response => setItem(response.data[0])); // esempio pathname "/contatti/5ec5098019b54d060c7b6835"
    }, [location]) //useEffect run only if location change
    
    useEffect(() => {
      const linkWAPP1_tel = "https://api.whatsapp.com/send?phone=";
      const linkWAPP2_nome = "&text=Ciao+";
      const linkWAPP3_id = "%2C%0D%0Aquesto+%C3%A8+il+link+alla+tua+tessera+digitale%2C+aggiornata+in+tempo+reale+con+i+nostri+appuntamenti+e+la+situazione+dei+servizi+che+hai+acquistato+%3A%29%0D%0AGrazie%2C+Fabio%0D%0A" + "http://www.lolacorre.it/mng/contatti/";

      setLinkEdit("/mng/add/" + item._id)
      setLlinkDocGoogle(item.doc_google)
      setLinkWapp(linkWAPP1_tel + item.cell + linkWAPP2_nome + item.nome + linkWAPP3_id + item._id)
    }, [item])

    useEffect(() => {
      let token = localStorage.getItem('token');
      axios.get(LOLAPROXY + '/api/mng/isauth/chk',
      { 'headers':
          {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          } 
        }).then(response => {if(response.status === 200) setStatoToken(true)});
    }, [])

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton ref={anchorRef} edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
          onClick={handleToggle}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
            {item.nome}  {item.cognome}
        </Typography>
        
        <Button color="inherit"
          onClick={() => { window.location.assign('https://www.lolacorre.it/')}}>            
          <HomeIcon />
        </Button>
        
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleMenuClose}>
                  <MenuList autoFocusItem={open} id="menu">
                  
                    <MenuItem>
                      <Link href="https://lolacorre.ddns.net:5555/api/mng/" underline="none" >
                        Accetta sito!
                      </Link>
                    </MenuItem>
                    {statoToken &&
                    <MenuItem>
                      <Link href={linkEdit} underline="none" >
                        Modifica Contatto
                      </Link>
                    </MenuItem>
                    }
                    {statoToken &&
                    <MenuItem>
                      <Link href={linkWapp} underline="none" >
                        Invia al Contatto
                      </Link>
                    </MenuItem>
                    }
                    {statoToken &&
                    <MenuItem>
                      <Link href={linkDocGoogle} underline="none" >
                        Doc Google
                      </Link>
                    </MenuItem>
                    }
                    
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
          
      </Toolbar>
    </AppBar>
  )
}

export default BarraCliente;