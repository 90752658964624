import { L_DAPAGARE, L_PAGATA, L_COMPRESA, ABB_ALTRO } from './utility/const';
import { I_FABIO, I_ANNA, I_FRANCESCA, I_CINZIA, I_IRENE } from './utility/const';
import { ABB_STAGE, ABB_GFORMAZIONE, ABB_AFFCAMPO, ABB_PROVA, ABB_AREASGAMBO, ABB_CORSOBASE, ABB_ABBSPORT, ABB_MINICORSO, ABB_FUORIABB, ABB_TESSERAMENTO } from './utility/const';

export const contattoSchema = [{
  '_id': '',
  'nome': '',
  'cognome': '',
  'nome_cane': '',
  'cell': '',
  'doc_google': '',
  'email' : '',
  'tessera' : '',
  'resoconto': '',
  'abbonamenti' : [{
      'ID' : '',
      'tipo': 'Fuori Abb',
      'n_lezioni': 0,
      'prezzo_tot': 0,
      'versati' : 0,
      'data': new Date(),
      'scadenza': new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      'pagamenti' : [{
          'ID' : '',
          'data': new Date(),
          'cifra': 0
        }],
      'lezioni' : [{
          'ID' : '',
          'data': new Date(),
          'luogo':'',
          'tipo': '',
          'istruttore': '',
          'cifra': 0,
          'xlola' : 0,
          'pagata': '',
          'note': ''
        }]
  }]

}];

export const ricevutaSchema = [{
  'numero': '',
  'socio' : '',
  'data': new Date(),
  'descrizione' : '',
  'totale' : '',
  'titolo' : 'Ricevuta'
}];

export const pagLezione = [
  {
  'stato': L_PAGATA
  },
  {
  'stato': L_DAPAGARE
  },
  {
  'stato': L_COMPRESA
  }
];

export const istruttore = [
  {
  'nome': I_FABIO
  },
  {
    'nome': I_IRENE
    },
  {
    'nome': I_FRANCESCA
    },
  {
  'nome': I_ANNA
  },
  {
  'nome': I_CINZIA
  }
];

export const abbTipo = [
  {
  'tipo': ABB_TESSERAMENTO,
  'n_lezioni': 0,
  'prezzo_tot': 15,
  'data': new Date(),
  'scadenza': new Date(new Date().getFullYear(), 11, 31)
  },
  {
  'tipo': ABB_FUORIABB
  },
  {
  'tipo': ABB_CORSOBASE,
  'n_lezioni': 10,
  'prezzo_tot': 300,
  'data': new Date(),
  'scadenza': new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  },
  {
  'tipo': ABB_MINICORSO,
  'n_lezioni': 5,
  'prezzo_tot': 160,
  'data': new Date(),
  'scadenza': new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  },
  {
  'tipo': ABB_ABBSPORT
  },
  {
  'tipo': ABB_GFORMAZIONE
  },
  {
  'tipo': ABB_AFFCAMPO
  },
  {
  'tipo': ABB_STAGE
  },
  {
  'tipo': ABB_AREASGAMBO
  },
  {
  'tipo': ABB_ALTRO
  },
  {
  'tipo': ABB_PROVA
  }
  
];