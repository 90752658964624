import React, {useState, useEffect}from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import 'devextreme-react/text-area';
import 'devextreme/dist/css/dx.material.orange.dark.compact.css';
import { locale } from 'devextreme/localization';
import { LOLAPROXY } from './utility/const';
import { printISODate, dateCompare, printISODateTime, printISOTime, printISODateExtend } from './utility/dateUtils';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import { DASH, SDASHS } from './utility/const';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ModeIcon from '@material-ui/icons/Create';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

export default function ElencoLezioni() {
  const classes = useStyles();
  const [initialFetchOnly] = useState(true);
  const [item, setItem] = useState([{}]);
  const oggiDate = new Date();
  const [data, setData] = useState(new Date(oggiDate.getTime() - (oggiDate.getTimezoneOffset() * 60000)).toISOString().slice(0,-14));

  
  //locale('it');
    useEffect(() => {
        let token = localStorage.getItem('token');
        axios.get(LOLAPROXY + '/api/mng/calendar',
        { 'headers':
          {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          } 
        }).then(response => {
            const listaStringify = JSON.stringify(response.data, null, 2);
            var resMod = JSON.parse(listaStringify, function (key, value) {  //trasformo stringhe in oggetti dove serve
                switch(key){
                    case "giorno":
                        return new Date(value);
                    case "ora": // ora non esiste più ma tengo cmq la case
                        return new Date(value).toLocaleDateString(undefined,{ hour: 'numeric', minute: 'numeric'});
                    default:
                    return value;
                }      
            });
            setItem(resMod);
        });
        
    }, [initialFetchOnly])
    
    return (
    <div>
      <div>

        <TextField fullWidth id="data" type = "date" label="Lezioni da" value={data} onChange={e => setData(e.target.value)}/>
        {item && item.filter((d) => new Date(d.giorno).getTime() > new Date(new Date(data).getTime()+new Date(data).getTimezoneOffset() * 60001)).sort((b, a) => dateCompare(a.giorno, b.giorno)).map((lezione, index, array) =>
        <div>
          <Typography variant="h6">
            {array[index-1] && printISODate(array[index-1].giorno) != printISODate(lezione.giorno) || index === 0 ? printISODateExtend(lezione.giorno) :''}
          </Typography>
          
            <List dense="true">
              <ListItem key={lezione.ID}>
                <Link href={lezione.linkWAPP + "%0D%0A" + "Clicca+per+vedere+tutte+le+lezioni+->+http://www.lolacorre.it/mng/contatti/" + lezione._id}>
                  <ListItemIcon>
                    <WhatsAppIcon />
                  </ListItemIcon>
                </Link>
                <ListItemText
                  primary={printISOTime(lezione.giorno) + SDASHS + lezione.istruttore + SDASHS + lezione.cliente + SDASHS + lezione.luogo}
                  secondary={lezione.tipo + SDASHS + lezione.cifra +"€" + SDASHS + lezione.pagata + SDASHS + lezione.note}
                />
                <Link href={lezione.linkModLez}>
                  <IconButton edge="end" aria-label="Modifica Lezione">
                    <ModeIcon />
                  </IconButton>
                </Link>
                <Link href={lezione.linkModCli}>
                  <IconButton edge="end" aria-label="Modifica Cliente">
                    <AssignmentIndIcon />
                  </IconButton>
                </Link>
              </ListItem>
            </List>
          
        </div>
        )}
        
      </div>
    </div>
  );
}