import React from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import BarraNavigazione from './BarraNavigazione';
import ContattiList from './ContattiList';
import Contatto from './Contatto';
import BarraCliente from './BarraCliente';
import AddModContatto from './AddContatto';
import NotFoundPage from './NotFoundPage';
import Calendar from './Calendar';
import Loggati from './Loggati';
import ModLezione from './ModLezione';
import ModPagamento from './ModPagamento';
import ElencoLezioni from './ElencoLezioni';

const Main = () =>
    <>
        
        <Switch >
            
            <Route path="/mng/contatti/:id">
            <BarraCliente /><Contatto />
            </Route>
            <Route path="/mng/contatti"> 
            <BarraNavigazione /><ContattiList />
            </Route>
            <Route path="/mng/add">
            <BarraNavigazione /><AddModContatto />
            </Route>            
            <Route path="/mng/calendar">
            <BarraNavigazione /><Calendar />
            </Route>   
            <Route path="/mng/lezioni">
            <BarraNavigazione /><ElencoLezioni />
            </Route>
            <Route path="/mng/entra">
            <BarraNavigazione /><Loggati />
            </Route>
            <Route path="/mng/modlezione">
            <BarraNavigazione /><ModLezione />
            </Route>
            <Route path="/mng/modpagamento">
            <BarraNavigazione /><ModPagamento />
            </Route>
            <Route path="/mng">
                <BarraNavigazione /><Loggati />
            </Route>         
            <Route>
                <NotFoundPage />
            </Route>
        </Switch>
    </>

export default Main;