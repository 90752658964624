import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
// import { DateTime } from 'luxon';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { red } from '@material-ui/core/colors';
import LezioneCard from './LezioneCard';
import PagamentoCard from './PagamentoCard';
import { SCADE_IL } from './utility/const';
import { printISODate, dateCompare } from './utility/dateUtils';
import { Typography, CardHeader } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { contattoSchema } from './data';
import { v4 as uuid } from 'uuid';
import axios from 'axios';
import { LOLAPROXY } from './utility/const';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const CarteContatto = ({
  item, contatto
  }) => {
    let history = useHistory();
    const [meetingExpanded, setMeetingExpanded] = useState(false);
    const [paymentExpanded, setPaymentExpand] = useState(false);
    const handleExpandClick = () => {
      setMeetingExpanded(!meetingExpanded);
    };

    const handlePaymentExpandClick = () => {
      setPaymentExpand(!paymentExpanded)
    };
    const classes = useStyles();
    const [statoToken, setStatoToken] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    let pagamentiEffettuati = 'Pagamenti effettuati';
  
  useEffect(() => {
    let token = localStorage.getItem('token');
    axios.get(LOLAPROXY + '/api/mng/isauth/chk',
    { 'headers':
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        } 
      }).then(response => {if(response.status === 200) setStatoToken(true)});
  }, [])
  
  if(item.prezzo_tot <= 0) pagamentiEffettuati += ' tot: ' + item.versati + '€';
    else pagamentiEffettuati +=  ' tot: ' + item.versati + '€ su ' + item.prezzo_tot + '€';

    const handleMenuAbbCard = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleCloseMenuAbbCard = () => {
      setAnchorEl(null);
    };
    const handleAddPagamento = () => {
            // aggiungi nuovo Pagamento vuoto nell'array con ID nuovo
            let indexAbb = contatto.abbonamenti.findIndex(function(abbonamento, i){
              return abbonamento.ID === item.ID
          });
            let nuovoPagamento = contattoSchema[0].abbonamenti[0].pagamenti[0];
            nuovoPagamento.ID = uuid();
            console.log(nuovoPagamento.ID);
            if(!contatto.abbonamenti[indexAbb].pagamenti) contatto.abbonamenti[indexAbb].pagamenti = [];
            contatto.abbonamenti[indexAbb].pagamenti.push(nuovoPagamento);
          
            /*--------  Scrittura nuova lezione vuota ----------------*/
          let token = localStorage.getItem('token');
          axios.post(LOLAPROXY + '/api/mng/add/', contatto,
          { 'headers':
                {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                } 
              }).then(res => {
            //setLoading(false);
      
          }).catch(err => {
            //setLoading(false);
            //setIsError(true);
          });
            /*--------  Fine Scrittura -------------------------*/
            console.log("indexAbb ->  "+indexAbb);
            history.push(`/mng/modpagamento/${nuovoPagamento.ID}`);
      
    };
    const handleAddLezione = () => {
      // aggiungi nuova lezione vuota nell'array con ID nuovo
      let indexAbb = contatto.abbonamenti.findIndex(function(abbonamento, i){
        return abbonamento.ID === item.ID
    });
      let nuovaLezione = contattoSchema[0].abbonamenti[0].lezioni[0];
      nuovaLezione.ID = uuid();
      console.log(nuovaLezione.ID);
      if(!contatto.abbonamenti[indexAbb].lezioni) contatto.abbonamenti[indexAbb].lezioni = [];
      contatto.abbonamenti[indexAbb].lezioni.push(nuovaLezione);
    
      /*--------  Scrittura nuova lezione vuota ----------------*/
    let token = localStorage.getItem('token');
    axios.post(LOLAPROXY + '/api/mng/add/', contatto,
    { 'headers':
          {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          } 
        }).then(res => {
      //setLoading(false);

    }).catch(err => {
      //setLoading(false);
      //setIsError(true);
    });
      /*--------  Fine Scrittura -------------------------*/
      console.log("indexAbb ->  "+indexAbb);
      history.push(`/mng/modlezione/${nuovaLezione.ID}`);
  };
  return (
    <Grid key={item.tipo} item xs={12} md={6}>
      <Card>
        <CardHeader
          title={<Typography variant="h6">{item.tipo}</Typography>}
          avatar={
            <Avatar className={classes.avatar}>
              {item.tipo && item.tipo.replace(/(\w)\w*\s(\w)\w*/, "$1$2")}
            </Avatar>
          }
          action={
            <IconButton onClick={handleMenuAbbCard}>
              {statoToken && <AddIcon />}
            </IconButton>
          }
          subheader={SCADE_IL + printISODate(item.scadenza)}
        />
        <Menu
            id="menu-AbbonamentoCard"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenuAbbCard}
          >
            <MenuItem onClick={handleAddPagamento}>+ Pagamento</MenuItem>
            <MenuItem onClick={handleAddLezione}>+ Lezione</MenuItem>
          </Menu>  
        {item.pagamenti ?
          <CardActions disableSpacing>
            <Box fontWeight="fontWeightBold">
               {pagamentiEffettuati}
            </Box>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: paymentExpanded,
              })}
              onClick={handlePaymentExpandClick}
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          :
          <ListItem button>
            <Box fontWeight="fontWeightBold">
              Non risultano pagamenti
              </Box>
          </ListItem>
        }
        {
          item.pagamenti &&
          <Collapse in={paymentExpanded} timeout="auto" unmountOnExit>
          {item.pagamenti
            && item.pagamenti.sort((a, b) => dateCompare(a.data, b.data)).map((pagamento) =>
              <PagamentoCard key={pagamento.id} item={pagamento} />
            )}
        </Collapse>
        }
        {
          item.lezioni &&
          <CardActions disableSpacing>
            <Box fontWeight="fontWeightBold">
              {item.n_lezioni ? 'Lezioni (' + item.lezioni.length + ' di ' + item.n_lezioni + ')': item.lezioni.length + ' Lezioni'}
            </Box>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: meetingExpanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={meetingExpanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
        }
        {
          item.lezioni &&
          <Collapse in={meetingExpanded} timeout="auto" unmountOnExit>
            {item.lezioni
              && item.lezioni.sort((a, b) => dateCompare(a.data, b.data)).map((lezione) =>
                <LezioneCard key={lezione.id} item={lezione} />
              )}
          </Collapse>
        }
        
      </Card>
    </Grid>
  )
}

export default CarteContatto
