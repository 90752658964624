import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AbbonamentoCard from './AbbonamentoCard';
import { LOLAPROXY } from './utility/const';
import LinearProgress from '@material-ui/core/LinearProgress';

const Contatto = () => {
    const [item, setItem] = useState({});
    let location = useLocation();
    const [openLinearProgress, setOpenLinearProgress] = React.useState(true);

    useEffect(() => {
        axios.get(LOLAPROXY + '/api' + location.pathname).then(response => {setItem(response.data[0]); setOpenLinearProgress(false);}); // esempio pathname "/contatti/5ec5098019b54d060c7b6835"
    }, [location]) //useEffect run only if location change

    return (
        <>
            {openLinearProgress && <LinearProgress />}       
            <Typography variant="h6">
                Nome Cane: {item.nome_cane}
            </Typography>
            <Typography variant="h7">
                Resoconto: {item.resoconto}
            </Typography>
            <Grid container
                direction="row"
                alignItems="flex-start"
                spacing={2}
            >
                {item.abbonamenti && item.abbonamenti.map((abbonamento) =>
                    <AbbonamentoCard key={abbonamento.id} item={abbonamento} contatto={item}/>
                )}        
            </Grid>
        </>
    );
}
export default Contatto;
