import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'flex-start',
    padding: 0,
    backgroundColor: '#fde8c1',
    flexGrow: 1
  },
  intestazione: {
    flex: 1,
    width: '90mm',
    height: '30mm',
    marginLeft: '49mm',
    marginTop: '8mm',
    position: 'absolute',
    flexGrow: 1,
    //alignContent: 'center',
    //backgroundColor: '#99999d',
  },
  corpo: {
    flex: 1,
    width: '120mm',
    height: '80mm',
    marginLeft: '15mm',
    marginTop: '76mm',
    position: 'absolute',
    flexGrow: 1,
  },
  titolo: {
    fontSize: '25',
    flex: 1,
    width: '120mm',
    height: '10mm',
    marginLeft: '15mm',
    marginTop: '55mm',
    position: 'absolute',
    flexGrow: 1,
    textAlign: 'center',
  },
  totale: {
    flex: 1,
    width: '75mm',
    height: '7mm',
    marginLeft: '59mm',
    marginTop: '163mm',
    position: 'absolute',
    flexGrow: 1,
    textAlign: 'right',
  },
  
  bksection: {
    flex: 1,
    width: '100%',
    height: '100%',
    marginHorizontal: 0,
    position: 'absolute',
    backgroundColor: '#fde8c1',
    flexGrow: 1
  }
});

// Create Document Component
export const Ricevuta = (props) => (
  <Document>
    <Page  size='A5' style={styles.page}>
  
      <View style={styles.bksection}>
      <Image src="/ricevutalola.jpg" />
      </View>
      <View style={styles.intestazione}>
        <Text>Lola Corre ASD </Text>
        <Text>Via Contrada 348/b </Text>
        <Text>41126 Modena </Text>
        <Text>C.F. 94186350362 </Text>
      </View>
      <View style={styles.titolo}>
        <Text>{props.titolo}</Text>
      </View>
      <View style={styles.corpo}>
        <Text>Data: {props.data}</Text>
        <Text>Socio: {props.socio}</Text>
        <Text>Descrizione: {props.descrizione}</Text>
      </View> 
      <View style={styles.totale}>
        <Text>Totale: {props.totale}</Text>
      </View>
    </Page>
  </Document>
);