import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { LOLAPROXY } from './utility/const';
import axios from 'axios';

const API_URL = LOLAPROXY;
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
  }));


export default function Loggati() {
    const classes = useStyles();
    const [utente, setUtente] = useState('');
    const [pw, setPassword] = useState('');

    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState('');
    const [isLogin, setIsLogin] = useState(true);
    const [loading, setLoading] = useState(false);

    const onSubmitHandler = () => {
        setLoading(true);
        const payload = {
            utente,
            pw,
        };
        fetch(`${API_URL}/api/mng/login/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
        .then(async res => { 
            try {
                const jsonRes = await res.json();
                if (res.status !== 200) {
                    setIsError(true);
                    setLoading(false);
                    setMessage(jsonRes.message);
                } else {
                    localStorage.setItem('token', jsonRes.token);
                    setIsError(false);
                    setLoading(false);
                    setMessage(jsonRes.message);
                }
            } catch (err) {
                console.log(err);
                setLoading(false);
            };
        })
        .catch(err => {
            console.log(err);
            setLoading(false);
        });
    };

    const getMessage = () => {
        const status = isError ? `Errore: ` : `:) `;
        return status + message;
    }

    return (
        <>
        <form className={classes.root} noValidate autoComplete="off">
        <TextField id="utente" label="Utente" onChange={e => setUtente(e.target.value)}/>
        <TextField id="pw" type="password" label="Password"  onChange={e => setPassword(e.target.value)} />
        <Typography style={{color: isError ? 'red' : 'green'}} variant="h6">
            {message ? getMessage() : null}
        </Typography>
        <Button type="submit" onClick={onSubmitHandler} disabled={loading} variant="contained">{loading ? 'Attendi...' : 'Entra'} </Button>
        </form>
        </>
        
    );
};