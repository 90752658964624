import React, {useState, useEffect}from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import { LOLAPROXY } from './utility/const';
import { v4 as uuid } from 'uuid';
import { controlloPagamenti } from './utility/dateUtils';

import DataGrid, {
  Column,
  FormItem,
  Editing,
  Paging,
  Lookup
} from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import 'devextreme/dist/css/dx.material.orange.dark.compact.css';
import { locale } from 'devextreme/localization';

import { contattoSchema, pagLezione, istruttore, abbTipo } from './data.js';

var contatto = contattoSchema;
var tipoAbbonamentoSelezionato = '';

const notesEditorOptions = { height: 100 };

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

export default function AddModContatto() {
  const classes = useStyles();
  const [idx, setIdx] = useState(0);
  const [item, setItem] = useState({});
  let vaiScheda = false;
  let location = useLocation();
   let history = useHistory();
  
    useEffect(() => {
      let token = localStorage.getItem('token');
      console.log('location.pathname --> ' + location.pathname);
       axios.get(LOLAPROXY + '/api' + location.pathname,
       { 'headers':
          {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          } 
        }).then(response => setItem(response.data[0])); // esempio pathname "contatti/5ec5098019b54d060c7b6835"
       setIdx(0);
    }, [location]) //useEffect run only if location change
   
    useEffect(() => {
        console.log('prepara contatto da item...'+ idx);
        contatto = item;
        setNome(contatto.nome);
        setCognome(contatto.cognome);
        setNomeCane(contatto.nome_cane);
        setTessera(contatto.tessera);
        setCell(contatto.cell);
        setEmail(contatto.email);
        contatto.doc_google ? setDocGoogle(contatto.doc_google) : setDocGoogle('https://docs.google.com/document/d/1juhYbRJvY5VQI31bRZqzm-0hVz0y9Uv7Dkzbg7068WQ/edit#');
        if(!contatto.abbonamenti) {
          contatto.abbonamenti = contattoSchema[0].abbonamenti;
          contatto.abbonamenti[0].ID = uuid();
          contatto.abbonamenti[0].pagamenti[0].ID = uuid();
          contatto.abbonamenti[0].lezioni[0].ID = uuid();
        }
        console.log('FINE prepara contatto da item');
    }, [item]) //useEffect run only if item change


  //costanti di stato per gestione form
  const [nome, setNome] = useState('');
  const [cognome, setCognome] = useState('');
  const [nome_cane, setNomeCane] = useState('');
  const [tessera, setTessera] = useState('');
  const [cell, setCell] = useState('+39');
  const [doc_google, setDocGoogle] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  //--------------------------

  const [datiPagamenti, setDatiPagamenti] = useState('');
  const [datiLezioni, setDatiLezioni] = useState('');
  locale('it');
  let DateFormat = {month: 'long', day: '2-digit', weekday: 'long', hour: 'numeric', minute: 'numeric'};
  //format={DateFormat}

  const handleSubmit = (vaiScheda) => {
    setLoading(true);
    setIsError(false);
    
    contatto.nome = nome;
    contatto.cognome = cognome;
    contatto.nome_cane = nome_cane;
    contatto.tessera = tessera;
    contatto.cell = cell;
    contatto.email = email;
    contatto.doc_google = doc_google;
    
    contatto = controlloPagamenti(contatto);
    console.log('/api/mng/add/',JSON.stringify(contatto, null, 2));
    let token = localStorage.getItem('token');
    axios.post(LOLAPROXY + '/api/mng/add/', contatto,
    { 'headers':
          {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          } 
        }).then(res => {
      setLoading(false);
      console.log('_id inserito ' + res.data);
      contatto._id = res.data;
      history.push("/mng/add/" + res.data);
      window.location.reload(false);
    }).catch(err => {
      setLoading(false);
      setIsError(true);
    });
  }

  const handleVaiScheda = () => {
    history.push(`/mng/contatti/${contatto._id}`);
  }

  const onSelectionAbbonamentiChanged = ({ selectedRowsData }) => {
    tipoAbbonamentoSelezionato = selectedRowsData[0].tipo;
    let indice = contatto.abbonamenti.findIndex(item => item.ID === selectedRowsData[0].ID);
    if(indice >=0) {
      setIdx(indice);
      
      if (!contatto.abbonamenti[indice].pagamenti) {
        contatto.abbonamenti[indice].pagamenti = contattoSchema[0].abbonamenti[0].pagamenti;
        contatto.abbonamenti[indice].pagamenti[0].ID = uuid();
      }
      setDatiPagamenti(contatto.abbonamenti[indice].pagamenti);

      if (!contatto.abbonamenti[indice].lezioni) {
        contatto.abbonamenti[indice].lezioni = contattoSchema[0].abbonamenti[0].lezioni;
        contatto.abbonamenti[indice].lezioni[0].ID = uuid();
      }
      setDatiLezioni(contatto.abbonamenti[indice].lezioni);
    }
  }

  function settaCellValue(newData, value, currentRowData) {
    newData.tipo = value;
    let indexAbbTipo = abbTipo.findIndex(function(item, i){
      return item.tipo === value
    });
    console.log("indexAbbTipo -> " + indexAbbTipo);
    newData.n_lezioni = abbTipo[indexAbbTipo].n_lezioni && abbTipo[indexAbbTipo].n_lezioni;
    newData.prezzo_tot = abbTipo[indexAbbTipo].prezzo_tot && abbTipo[indexAbbTipo].prezzo_tot;
    newData.data = abbTipo[indexAbbTipo].data && abbTipo[indexAbbTipo].data;
    newData.scadenza = abbTipo[indexAbbTipo].scadenza && abbTipo[indexAbbTipo].scadenza;
  }
  
  
  return (
    <div>
      <Typography variant="h7">
        Resoconto: {contatto.resoconto}
      </Typography>
        <form className={classes.root} noValidate autoComplete="off">
        <TextField id="nome" label="Nome" value={nome} onChange={e => setNome(e.target.value)}/>
        <TextField id="cognome" label="Cognome" value={cognome} onChange={e => setCognome(e.target.value)} />
        <TextField id="nome_cane" label="Nome Cane" value={nome_cane} onChange={e => setNomeCane(e.target.value)} />
        <TextField id="tessera" label="Tessera" value={tessera} onChange={e => setTessera(e.target.value)} />
        <TextField id="cell" label="Cellulare" value={cell} onChange={e => setCell(e.target.value)} />
        <TextField id="email" label="E-Mail" value={email} onChange={e => setEmail(e.target.value)} />
        <TextField id="doc_google" label="Link Scheda Google" value={doc_google} onChange={e => setDocGoogle(e.target.value)} />

        <Button type="submit" onClick={handleSubmit} disabled={loading} variant="contained">{loading ? 'Attendi...' : 'Salva'} </Button>
        <Button type="submit" onClick={handleVaiScheda} disabled={loading} variant="contained">{loading ? 'Attendi...' : 'Vai a Scheda'} </Button>
        </form>

        <div id="data-grid-Abbonamenti">
        
        <Typography variant="h6">
            Abbonamenti
            </Typography>
        <DataGrid
            dataSource={contatto.abbonamenti}
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            onSelectionChanged={onSelectionAbbonamentiChanged}
            keyExpr="ID"
            showBorders={true}
        >
            <Paging enabled={false} />
            <Editing
            mode="popup"
            allowUpdating={true}
            allowAdding={true}
            allowDeleting={true}
            />
            <Column dataField="tipo" caption="Tipo Abb." setCellValue={settaCellValue}>
              <Lookup dataSource={abbTipo} displayExpr="tipo" valueExpr="tipo" />
            </Column>
            <Column dataField="n_lezioni" caption = "N. Lez." width = "70"/>
            <Column dataField="prezzo_tot" width = "80" hidingPriority={3}/>
            <Column dataField="data"  dataType="date" hidingPriority={1}/>
            <Column dataField="scadenza" dataType="date" hidingPriority={4}/>                                  
            <Column dataField="versati" hidingPriority={2}/>
        </DataGrid>
        </div>
        
        <div id="data-grid-Pagamenti" >
        <Typography variant="h6">
            {tipoAbbonamentoSelezionato} - Pagamenti
            </Typography>
        <DataGrid
            dataSource={datiPagamenti}
            keyExpr="ID"
            showBorders={true}
        >
            <Paging enabled={false} />
            <Editing
            mode="popup"
            allowUpdating={true}
            allowAdding={true}
            allowDeleting={true}
            />
            <Column dataField="data" dataType="date" />
            <Column dataField="cifra" /> 
            
        </DataGrid>
        </div>

        <div id="data-grid-Lezioni" >
        <Typography variant="h6">
            {tipoAbbonamentoSelezionato} - Lezioni
            </Typography>
        <DataGrid
            dataSource={datiLezioni}
            keyExpr="ID"
            showBorders={true}
        >
            <Paging enabled={false} />
            <Editing
            mode="popup"
            allowUpdating={true}
            allowAdding={true}
            allowDeleting={true}
            />

            <Column dataField="data"  width = "150" dataType="datetime" />
            <Column dataField="tipo" />
            <Column dataField="istruttore" width = "100">
              <Lookup dataSource={istruttore} displayExpr="nome" valueExpr="nome" />
            </Column>
            <Column dataField="luogo"  hidingPriority={2}/>
            <Column dataField="cifra"  width = "70" hidingPriority={3}/>
            <Column dataField="xlola"  caption = "x Lola" width = "70" hidingPriority={2}/>
            <Column dataField="pagata" hidingPriority={4}>
              <Lookup dataSource={pagLezione} displayExpr="stato" valueExpr="stato" />
            </Column> 
            <Column dataField="note" hidingPriority={1}>
            <FormItem colSpan={2} editorType="dxTextArea" editorOptions={notesEditorOptions} />
            </Column>                                                           
        </DataGrid>
        </div>

    </div>
  );
}