import { SLASH, DASH, COLON, SPACE, L_DAPAGARE, ERR_L_DAPAGARE, L_PAGATA, WEEKDAY, MONTHNAME } from './const';
import { ricevutaSchema } from '../data';

export const printISODate = (inDate) => {
    const date = new Date(inDate);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
        dt = '0' + dt;
    }
    if (month < 10) {
        month = '0' + month;
    }
    return dt + SLASH + month + SLASH + year
}

export const printISODateExtend = (inDate) => {
    const date = new Date(inDate);
    const year = date.getFullYear();
    const hours = date.getHours();
    const day = WEEKDAY[date.getDay()];
    let minutes = date.getMinutes();
    const month = MONTHNAME[date.getMonth()];
    let dt = date.getDate();

    if (dt < 10) {
        dt = '0' + dt;
    }
    
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    return day + SPACE + dt + SPACE + month + SPACE + year  
}

export const printISODateTime = (inDate) => {
    const date = new Date(inDate);
    const year = date.getFullYear();
    const hours = date.getHours();
    const day = WEEKDAY[date.getDay()];
    let minutes = date.getMinutes();
    const month = MONTHNAME[date.getMonth()];
    let dt = date.getDate();

    if (dt < 10) {
        dt = '0' + dt;
    }
    
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    return day + SPACE + dt + SPACE + month + SPACE + year + DASH + hours + COLON + minutes  
}

export const printISOTime = (inDate) => {
    const date = new Date(inDate);
    const hours = date.getHours();
    let minutes = date.getMinutes();
    
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    return hours + COLON + minutes  
}

export const dateCompare = (a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return (dateA < dateB) -  (dateA > dateB);
}

export const controlloPagamenti = (contatto) => {
    let totPagamentiAbbonamento = 0;
    contatto.resoconto = '';

    if(contatto.abbonamenti){
        for(var a=0; a< contatto.abbonamenti.length; a++){
            if(contatto.abbonamenti[a].pagamenti) {
                for(var p=0; p< contatto.abbonamenti[a].pagamenti.length; p++){
                    totPagamentiAbbonamento += parseInt(contatto.abbonamenti[a].pagamenti[p].cifra, 10);              
                }
            }
            
            if(contatto.abbonamenti[a].lezioni) {

                for(var l=0; l< contatto.abbonamenti[a].lezioni.length; l++){

                    if (contatto.abbonamenti[a].lezioni[l].pagata && contatto.abbonamenti[a].lezioni[l].pagata == L_PAGATA) {
                        totPagamentiAbbonamento += parseInt(contatto.abbonamenti[a].lezioni[l].cifra, 10);
                    }
                    
                    if (contatto.abbonamenti[a].lezioni[l].pagata &&
                        contatto.abbonamenti[a].lezioni[l].cifra > 0 &&
                        contatto.abbonamenti[a].lezioni[l].pagata == L_DAPAGARE &&
                        new Date(contatto.abbonamenti[a].lezioni[l].data) < new Date()) {
                        contatto.resoconto += DASH + SPACE + ERR_L_DAPAGARE + SPACE;
                    }
                }
                if (contatto.abbonamenti[a].n_lezioni > 0){
                    if(contatto.abbonamenti[a].lezioni.length >= contatto.abbonamenti[a].n_lezioni){
                        contatto.resoconto += DASH + SPACE + contatto.abbonamenti[a].tipo + " Esaurito" + SPACE;
                    } else{
                        if(new Date(contatto.abbonamenti[a].scadenza) < new Date()){
                            contatto.resoconto += DASH + SPACE + contatto.abbonamenti[a].tipo + " Scaduto" + SPACE;
                        }
                    }
                }
                
            }
            if(contatto.abbonamenti[a].prezzo_tot && contatto.abbonamenti[a].prezzo_tot > 0 && totPagamentiAbbonamento < contatto.abbonamenti[a].prezzo_tot) {
                contatto.resoconto += DASH + SPACE + contatto.abbonamenti[a].tipo + " da pagare" + SPACE;
            }
            
            contatto.abbonamenti[a].versati = totPagamentiAbbonamento;
            totPagamentiAbbonamento = 0;
        } 
    }
    
    return contatto;
}
export const addModRicevuta = (contatto, indexAbb, indexLez, indexPag, data, titolo, socio, descrizione, totale) => {
    console.log(indexAbb+" "+ indexLez+" "+ indexPag+" "+ titolo+" "+ socio+" "+ descrizione+" "+ totale)
    let ricevutaAdd = ricevutaSchema;
    ricevutaAdd[0].data = data;
    ricevutaAdd[0].titolo = titolo;
    ricevutaAdd[0].socio = socio;
    ricevutaAdd[0].descrizione = descrizione;
    ricevutaAdd[0].totale = totale;

    if(indexLez == -1 && indexPag == -1){
        // ricevuta per tessera soci
        console.log("ricevuta per tessera soci");
        contatto.ricevuta = ricevutaAdd;
    }
    else {
        if(indexPag != -1){
            //ricevuta pagamento
            console.log("ricevuta per Pagamento");
            contatto.abbonamenti[indexAbb].pagamenti[indexPag].ricevuta = ricevutaAdd;
        }
        else {
            //ricevuta lezione
            console.log("ricevuta per lezione");
            contatto.abbonamenti[indexAbb].lezioni[indexLez].ricevuta = ricevutaAdd;
        }
    }
    return contatto;
}