import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import Main from './Main';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#FFA000', contrastText: '#3E2723' },
    secondary: { main: '#C0CA33', contrastText: '#3E2723' },
    background: { paper: "#FFDED3", default: "#FAFAFA" }
  }
});

const App = () =>
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  </ThemeProvider>

export default App;