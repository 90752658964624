import React, {useState, useEffect}from 'react';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { COLON, SPACE, DASH } from './utility/const';
import { printISODateTime } from './utility/dateUtils';
import { Typography } from '@material-ui/core';
import Blink from 'react-blink-text';
import { Ricevuta } from './Ricevuta';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ModeIcon from '@material-ui/icons/Create';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import { LOLAPROXY } from './utility/const';


const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}));

const PagamentoCard = ({
  item
}) => {

  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [statoToken, setStatoToken] = useState(false);
  let token = localStorage.getItem('token');

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const dataDaConvertire = item.ricevuta && new Date (item.ricevuta[0].data);
  const dataPdf = dataDaConvertire && new Date(dataDaConvertire.getTime() - (dataDaConvertire.getTimezoneOffset() * 60000)).toISOString().slice(0,-8)

  useEffect(() => {
    let token = localStorage.getItem('token');
    axios.get(LOLAPROXY + '/api/mng/isauth/chk',
    { 'headers':
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        } 
      }).then(response => {if(response.status === 200) setStatoToken(true)});
  }, []) 
  return (
    <>
      <ListItem button onClick={handleExpandClick}>
         <ListItemText>
          {printISODateTime(item.data) + COLON + SPACE}
          {( item.cifra > 0 && item.cifra )}
          {( item.cifra > 0 && " ,00€" )} 
        </ListItemText>
        { statoToken &&
          <Link href={"/mng/modpagamento/"+ item.ID}>
            <IconButton edge="end" aria-label="Modifica Pagamento">
              <ModeIcon />
            </IconButton>
          </Link>
        }
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </ListItem >
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        
          <ListItem button>
            <Typography variant="body2">{item.note}</Typography>
            { item.ricevuta &&
              <PDFDownloadLink document={<Ricevuta socio={item.ricevuta[0].socio}
                                        data={new Date(item.ricevuta[0].data).toLocaleDateString()}
                                        descrizione={item.ricevuta[0].descrizione}
                                        totale={item.ricevuta[0].totale}
                                        titolo={item.ricevuta[0].titolo}
                                        />}
                                fileName={"RicevutaLolaCorre"+dataPdf+".pdf"}>
                {({ blob, url, loading, error }) =>
                  loading ? 'Loading document...' : 'Scarica ricevuta!'
                }
              </PDFDownloadLink>
            }
          </ListItem>
          <Divider variant="middle" />
      </Collapse>
    </>
  )
}

export default PagamentoCard
